import { combineReducers } from "redux";
import {
  eventsListReducer,
  eventsCreateReducer,
  eventsDetailReducer,
  eventsUpdateReducer,
  eventsStatsReducer,
} from "./eventReducers";
import {
  allSessionsReducer,
  sessionsListReducer,
  sessionsCreateReducer,
  sessionsDetailReducer,
  sessionsUpdateReducer,
} from "./sessionReducers";
import {
  sessionAttachmentsListReducer,
  sessionAttachmentsCreateReducer,
  sessionAttachmentsDetailReducer,
  sessionAttachmentsUpdateReducer,
} from "./sessionAttachmentReducers";
import {
  testimonialsListReducer,
  testimonialsCreateReducer,
  testimonialsDetailReducer,
  testimonialsUpdateReducer,
} from "./testimonialReducers";
import {
  testimonialAttachmentsListReducer,
  testimonialAttachmentsCreateReducer,
  testimonialAttachmentsDetailReducer,
  testimonialAttachmentsUpdateReducer,
} from "./testimonialAttachmentReducers";
import {
  partnersListReducer,
  partnersCreateReducer,
  partnersDetailReducer,
  partnersUpdateReducer,
} from "./partnerReducers";
import filterReducer from "./filterReducer";
import {
  dropdownDetails,
  organizationsListReducer,
  organizationsCreateReducer,
} from "./dropdownReducers";
import { sectorsListReducer } from "./sectorReducers";
import { categoriesListReducer } from "./categoryReducers";
import { programsListReducer } from "./programReducers";
import { typeOfEventsListReducer } from "./typeOfEventReducers";
import {
  locationsCreateReducer,
  locationsListReducer,
  locationsUpdateReducer,
} from "./locationReducers";

const rootReducer = combineReducers({
  eventsList: eventsListReducer,
  eventsDetails: eventsDetailReducer,
  eventsCreate: eventsCreateReducer,
  eventsUpdate: eventsUpdateReducer,
  eventsStats: eventsStatsReducer,
  allSessions: allSessionsReducer,
  sessionsList: sessionsListReducer,
  sessionsDetails: sessionsDetailReducer,
  sessionsCreate: sessionsCreateReducer,
  sessionsUpdate: sessionsUpdateReducer,
  sessionAttachmentsList: sessionAttachmentsListReducer,
  sessionAttachmentsDetails: sessionAttachmentsDetailReducer,
  sessionAttachmentsCreate: sessionAttachmentsCreateReducer,
  sessionAttachmentsUpdate: sessionAttachmentsUpdateReducer,
  testimonialsList: testimonialsListReducer,
  testimonialsDetails: testimonialsDetailReducer,
  testimonialsCreate: testimonialsCreateReducer,
  testimonialsUpdate: testimonialsUpdateReducer,
  testimonialAttachmentsList: testimonialAttachmentsListReducer,
  testimonialAttachmentsDetails: testimonialAttachmentsDetailReducer,
  testimonialAttachmentsCreate: testimonialAttachmentsCreateReducer,
  testimonialAttachmentsUpdate: testimonialAttachmentsUpdateReducer,
  partnersList: partnersListReducer,
  partnersDetails: partnersDetailReducer,
  partnersCreate: partnersCreateReducer,
  partnersUpdate: partnersUpdateReducer,
  filter: filterReducer,
  dropdownDetails,
  organizationsList: organizationsListReducer,
  organizationsCreate: organizationsCreateReducer,
  sectorsList: sectorsListReducer,
  categoriesList: categoriesListReducer,
  programsList: programsListReducer,
  typeOfEventsList: typeOfEventsListReducer,
  locationsList: locationsListReducer,
  locationsCreate: locationsCreateReducer,
  locationsUpdate: locationsUpdateReducer,
});

export default rootReducer;
