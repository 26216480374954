import React from "react";
import PropType from "prop-types";
import MessageDisplay from "../../../components/common/MessageDisplay";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({
      error,
      errorInfo,
    });
  }

  render() {
    const { children } = this.props;
    const { errorInfo, error } = this.state;

    if (errorInfo) {
      return (
        <div className="error-boundary-section">
          <details style={{ whiteSpace: "pre-wrap" }}>
            <MessageDisplay
              message="Something went wrong :("
              description={error && error.toString()}
            />
          </details>
        </div>
      );
    }
    return children;
  }
}

ErrorBoundary.propTypes = {
  children: PropType.oneOfType([PropType.arrayOf(PropType.node), PropType.node])
    .isRequired,
};

export default ErrorBoundary;
