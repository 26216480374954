import {
  TESTIMONIALS_LIST_REQUEST,
  TESTIMONIALS_LIST_SUCCESS,
  TESTIMONIALS_LIST_FAILURE,
  TESTIMONIALS_DETAILS_REQUEST,
  TESTIMONIALS_DETAILS_SUCCESS,
  TESTIMONIALS_DETAILS_FAILURE,
  TESTIMONIALS_CREATE_REQUEST,
  TESTIMONIALS_CREATE_SUCCESS,
  TESTIMONIALS_CREATE_FAILURE,
  TESTIMONIALS_CREATE_RESET,
  TESTIMONIALS_UPDATE_REQUEST,
  TESTIMONIALS_UPDATE_SUCCESS,
  TESTIMONIALS_UPDATE_FAILURE,
  TESTIMONIALS_UPDATE_RESET,
} from "../types/testimonialTypes";

const initialState = {
  loading: true,
  testimonials: [],
  filteredTestimonials: [],
};

export const testimonialsListReducer = (state = initialState, action) => {
  switch (action.type) {
    case TESTIMONIALS_LIST_REQUEST:
      return { loading: true };
    case TESTIMONIALS_LIST_SUCCESS:
      return {
        loading: false,
        testimonials: action.payload,
        filteredTestimonials: action.payload,
      };
    case TESTIMONIALS_LIST_FAILURE:
      return {
        loading: false,
        error: action.payload,
        testimonials: [],
        filteredTestimonials: [],
      };
    default:
      return state;
  }
};

export const testimonialsDetailReducer = (
  state = { loading: true },
  action,
) => {
  switch (action.type) {
    case TESTIMONIALS_DETAILS_REQUEST:
      return { loading: true };
    case TESTIMONIALS_DETAILS_SUCCESS:
      return { loading: false, testimonial: action.payload };
    case TESTIMONIALS_DETAILS_FAILURE:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const testimonialsCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case TESTIMONIALS_CREATE_REQUEST:
      return { loading: true };
    case TESTIMONIALS_CREATE_SUCCESS:
      return { loading: false, success: true, testimonials: action.payload };
    case TESTIMONIALS_CREATE_FAILURE:
      return { loading: false, error: action.payload };
    case TESTIMONIALS_CREATE_RESET:
      return {};
    default:
      return state;
  }
};

export const testimonialsUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case TESTIMONIALS_UPDATE_REQUEST:
      return { loading: true };
    case TESTIMONIALS_UPDATE_SUCCESS:
      return { loading: false, success: true };
    case TESTIMONIALS_UPDATE_FAILURE:
      return { loading: false, error: action.payload };
    case TESTIMONIALS_UPDATE_RESET:
      return {};
    default:
      return state;
  }
};
