export const ALL_SESSIONS_LIST_REQUEST = "ALL_SESSIONS_LIST_REQUEST";
export const ALL_SESSIONS_LIST_SUCCESS = "ALL_SESSIONS_LIST_SUCCESS";
export const ALL_SESSIONS_LIST_FAILURE = "ALL_SESSIONS_LIST_FAILURE";

export const SESSIONS_LIST_REQUEST = "SESSIONS_LIST_REQUEST";
export const SESSIONS_LIST_SUCCESS = "SESSIONS_LIST_SUCCESS";
export const SESSIONS_LIST_FAILURE = "SESSIONS_LIST_FAILURE";

export const SESSIONS_DETAILS_REQUEST = "SESSIONS_DETAILS_REQUEST";
export const SESSIONS_DETAILS_SUCCESS = "SESSIONS_DETAILS_SUCCESS";
export const SESSIONS_DETAILS_FAILURE = "SESSIONS_DETAILS_FAILURE";

export const SESSIONS_CREATE_REQUEST = "SESSIONS_CREATE_REQUEST";
export const SESSIONS_CREATE_SUCCESS = "SESSIONS_CREATE_SUCCESS";
export const SESSIONS_CREATE_FAILURE = "SESSIONS_CREATE_FAILURE";
export const SESSIONS_CREATE_RESET = "SESSIONS_CREATE_RESET";

export const SESSIONS_UPDATE_REQUEST = "SESSIONS_UPDATE_REQUEST";
export const SESSIONS_UPDATE_SUCCESS = "SESSIONS_UPDATE_SUCCESS";
export const SESSIONS_UPDATE_FAILURE = "SESSIONS_UPDATE_FAILURE";
export const SESSIONS_UPDATE_RESET = "SESSIONS_UPDATE_RESET";
