import {
  CLEAR,
  ORGANIZATION,
  ORGANIZATIONS_LIST_REQUEST,
  ORGANIZATIONS_LIST_SUCCESS,
  ORGANIZATIONS_LIST_FAILURE,
  ORGANIZATIONS_CREATE_REQUEST,
  ORGANIZATIONS_CREATE_SUCCESS,
  ORGANIZATIONS_CREATE_FAILURE,
} from "../types/dropdownTypes";

import data from "../../data";

// constants
const dummyInitialState = {
  organization: data.organization,
};

const initialState = {
  loading: true,
  organizations: [],
  filteredOrganizations: [],
};

// reducer
export const organizationsListReducer = (state = initialState, action) => {
  switch (action.type) {
    case ORGANIZATIONS_LIST_REQUEST:
      return { loading: true };
    case ORGANIZATIONS_LIST_SUCCESS:
      return {
        loading: false,
        organizations: action.payload,
        filteredOrganizations: action.payload,
      };
    case ORGANIZATIONS_LIST_FAILURE:
      return {
        loading: false,
        error: action.payload,
        organizations: [],
        filteredOrganizations: [],
      };
    default:
      return state;
  }
};

export const organizationsCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case ORGANIZATIONS_CREATE_REQUEST:
      return { loading: true };
    case ORGANIZATIONS_CREATE_SUCCESS:
      return { loading: false, success: true, organizations: action.payload };
    case ORGANIZATIONS_CREATE_FAILURE:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const dropdownDetails = (state = dummyInitialState, action) => {
  switch (action.type) {
    case CLEAR:
      return {
        ...state,
        ...dummyInitialState,
      };
    case ORGANIZATION:
      return {
        ...state,
        organization: action.payload,
      };
    default:
      return { ...state };
  }
};
