import {
  CATEGORIES_LIST_REQUEST,
  CATEGORIES_LIST_SUCCESS,
  CATEGORIES_LIST_FAILURE,
  CATEGORIES_CREATE_REQUEST,
  CATEGORIES_CREATE_SUCCESS,
  CATEGORIES_CREATE_FAILURE,
} from "../types/categoryTypes";

const initialState = {
  loading: true,
  categories: [],
  filteredCategories: [],
};

// reducer
export const categoriesListReducer = (state = initialState, action) => {
  switch (action.type) {
    case CATEGORIES_LIST_REQUEST:
      return { loading: true };
    case CATEGORIES_LIST_SUCCESS:
      return {
        loading: false,
        categories: action.payload,
        filteredCategories: action.payload,
      };
    case CATEGORIES_LIST_FAILURE:
      return {
        loading: false,
        error: action.payload,
        categories: [],
        filteredCategories: [],
      };
    default:
      return state;
  }
};

export const categoriesCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case CATEGORIES_CREATE_REQUEST:
      return { loading: true };
    case CATEGORIES_CREATE_SUCCESS:
      return { loading: false, success: true, categories: action.payload };
    case CATEGORIES_CREATE_FAILURE:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
