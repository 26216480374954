import {
  PROGRAMS_LIST_REQUEST,
  PROGRAMS_LIST_SUCCESS,
  PROGRAMS_LIST_FAILURE,
  PROGRAMS_CREATE_REQUEST,
  PROGRAMS_CREATE_SUCCESS,
  PROGRAMS_CREATE_FAILURE,
} from "../types/programTypes";

const initialState = {
  loading: true,
  programs: [],
  filteredPrograms: [],
};

// reducer
export const programsListReducer = (state = initialState, action) => {
  switch (action.type) {
    case PROGRAMS_LIST_REQUEST:
      return { loading: true };
    case PROGRAMS_LIST_SUCCESS:
      return {
        loading: false,
        programs: action.payload,
        filteredPrograms: action.payload,
      };
    case PROGRAMS_LIST_FAILURE:
      return {
        loading: false,
        error: action.payload,
        programs: [],
        filteredPrograms: [],
      };
    default:
      return state;
  }
};

export const programsCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case PROGRAMS_CREATE_REQUEST:
      return { loading: true };
    case PROGRAMS_CREATE_SUCCESS:
      return { loading: false, success: true, programs: action.payload };
    case PROGRAMS_CREATE_FAILURE:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
