import {
  LOCATIONS_LIST_REQUEST,
  LOCATIONS_LIST_SUCCESS,
  LOCATIONS_LIST_FAILURE,
  LOCATIONS_CREATE_REQUEST,
  LOCATIONS_CREATE_SUCCESS,
  LOCATIONS_CREATE_FAILURE,
  LOCATIONS_UPDATE_REQUEST,
  LOCATIONS_UPDATE_SUCCESS,
  LOCATIONS_UPDATE_FAILURE,
  LOCATIONS_UPDATE_RESET,
} from "../types/locationTypes";

const initialState = {
  loading: true,
  locations: [],
  filteredLocations: [],
};

// reducer
export const locationsListReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOCATIONS_LIST_REQUEST:
      return { loading: true };
    case LOCATIONS_LIST_SUCCESS:
      return {
        loading: false,
        locations: action.payload,
        filteredLocations: action.payload,
      };
    case LOCATIONS_LIST_FAILURE:
      return {
        loading: false,
        error: action.payload,
        locations: [],
        filteredLocations: [],
      };
    default:
      return state;
  }
};

export const locationsCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case LOCATIONS_CREATE_REQUEST:
      return { loading: true };
    case LOCATIONS_CREATE_SUCCESS:
      return { loading: false, success: true, locations: action.payload };
    case LOCATIONS_CREATE_FAILURE:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const locationsUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case LOCATIONS_UPDATE_REQUEST:
      return { loading: true };
    case LOCATIONS_UPDATE_SUCCESS:
      return { loading: false, success: true };
    case LOCATIONS_UPDATE_FAILURE:
      return { loading: false, error: action.payload };
    case LOCATIONS_UPDATE_RESET:
      return {};
    default:
      return state;
  }
};
