const data = {
  organization: [
    {
      _id: 1,
      name: "Accenture",
    },
    {
      _id: 2,
      name: "SRM College",
    },
    {
      _id: 3,
      name: "CRPF",
    },
    {
      _id: 4,
      name: "BPCL",
    },
    {
      _id: 5,
      name: "Marvel Apts",
    },
    {
      _id: 6,
      name: "Delhi Public School",
    },
    {
      _id: 8,
      name: "Govt School",
    },
    {
      _id: 9,
      name: "Air Force School",
    },
    {
      _id: 10,
      name: "Amer Public School",
    },
    {
      _id: 11,
      name: "Others",
    },
  ],
};

export default data;
