import {
  PARTNERS_LIST_REQUEST,
  PARTNERS_LIST_SUCCESS,
  PARTNERS_LIST_FAILURE,
  PARTNERS_DETAILS_REQUEST,
  PARTNERS_DETAILS_SUCCESS,
  PARTNERS_DETAILS_FAILURE,
  PARTNERS_CREATE_REQUEST,
  PARTNERS_CREATE_SUCCESS,
  PARTNERS_CREATE_FAILURE,
  PARTNERS_CREATE_RESET,
  PARTNERS_UPDATE_REQUEST,
  PARTNERS_UPDATE_SUCCESS,
  PARTNERS_UPDATE_FAILURE,
  PARTNERS_UPDATE_RESET,
} from "../types/partnerTypes";

const initialState = {
  loading: true,
  partners: [],
  filteredPartners: [],
};

export const partnersListReducer = (state = initialState, action) => {
  switch (action.type) {
    case PARTNERS_LIST_REQUEST:
      return { loading: true };
    case PARTNERS_LIST_SUCCESS:
      return {
        loading: false,
        partners: action.payload,
        filteredPartners: action.payload,
      };
    case PARTNERS_LIST_FAILURE:
      return {
        loading: false,
        error: action.payload,
        partners: [],
        filteredPartners: [],
      };
    default:
      return state;
  }
};

export const partnersDetailReducer = (state = { loading: true }, action) => {
  switch (action.type) {
    case PARTNERS_DETAILS_REQUEST:
      return { loading: true };
    case PARTNERS_DETAILS_SUCCESS:
      return { loading: false, partner: action.payload };
    case PARTNERS_DETAILS_FAILURE:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const partnersCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case PARTNERS_CREATE_REQUEST:
      return { loading: true };
    case PARTNERS_CREATE_SUCCESS:
      return { loading: false, success: true, partners: action.payload };
    case PARTNERS_CREATE_FAILURE:
      return { loading: false, error: action.payload };
    case PARTNERS_CREATE_RESET:
      return {};
    default:
      return state;
  }
};

export const partnersUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case PARTNERS_UPDATE_REQUEST:
      return { loading: true };
    case PARTNERS_UPDATE_SUCCESS:
      return { loading: false, success: true };
    case PARTNERS_UPDATE_FAILURE:
      return { loading: false, error: action.payload };
    case PARTNERS_UPDATE_RESET:
      return {};
    default:
      return state;
  }
};
