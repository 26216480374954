import {
  EVENTS_LIST_REQUEST,
  EVENTS_LIST_SUCCESS,
  EVENTS_LIST_FAIL,
  EVENTS_DETAILS_REQUEST,
  EVENTS_DETAILS_SUCCESS,
  EVENTS_DETAILS_FAIL,
  EVENTS_CREATE_REQUEST,
  EVENTS_CREATE_SUCCESS,
  EVENTS_CREATE_FAIL,
  EVENTS_CREATE_RESET,
  EVENTS_UPDATE_REQUEST,
  EVENTS_UPDATE_SUCCESS,
  EVENTS_UPDATE_FAIL,
  EVENTS_UPDATE_RESET,
  EVENTS_STATS_REQUEST,
  EVENTS_STATS_FAIL,
  EVENTS_STATS_SUCCESS,
  EVENTS_STATS_RESET,
  TOGGLE_CREATE_EVENT_PAGE_SHOWN,
} from "../types/eventTypes";

const initialState = {
  loading: true,
  eventsCount: 0,
  createEventPageShown: false,
  events: [],
  filteredEvents: [],
  stats: {},
};

export const eventsListReducer = (state = initialState, action) => {
  switch (action.type) {
    case EVENTS_LIST_REQUEST:
      return { ...state, loading: true };
    case EVENTS_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        eventsCount: action.payload.count,
        events: action.payload.results,
        filteredEvents: action.payload.results,
      };
    case EVENTS_LIST_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        eventsCount: 0,
        events: [],
        filteredEvents: [],
      };
    case TOGGLE_CREATE_EVENT_PAGE_SHOWN:
      return { ...state, createEventPageShown: !state.createEventPageShown };
    default:
      return state;
  }
};

export const eventsDetailReducer = (state = { loading: true }, action) => {
  switch (action.type) {
    case EVENTS_DETAILS_REQUEST:
      return { loading: true };
    case EVENTS_DETAILS_SUCCESS:
      return { loading: false, event: action.payload };
    case EVENTS_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const eventsCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case EVENTS_CREATE_REQUEST:
      return { loading: true };
    case EVENTS_CREATE_SUCCESS:
      return { loading: false, success: true, events: action.payload };
    case EVENTS_CREATE_FAIL:
      return { loading: false, error: action.payload };
    case EVENTS_CREATE_RESET:
      return {};
    default:
      return state;
  }
};

export const eventsUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case EVENTS_UPDATE_REQUEST:
      return { loading: true };
    case EVENTS_UPDATE_SUCCESS:
      return { loading: false, success: true };
    case EVENTS_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    case EVENTS_UPDATE_RESET:
      return {};
    default:
      return state;
  }
};

export const eventsStatsReducer = (state = {}, action) => {
  switch (action.type) {
    case EVENTS_STATS_REQUEST:
      return { loading: true };
    case EVENTS_STATS_SUCCESS:
      return { loading: false, success: true, stats: action.payload };
    case EVENTS_STATS_FAIL:
      return { loading: false, error: action.payload };
    case EVENTS_STATS_RESET:
      return {};
    default:
      return state;
  }
};
