import {
  ALL_SESSIONS_LIST_REQUEST,
  ALL_SESSIONS_LIST_SUCCESS,
  ALL_SESSIONS_LIST_FAILURE,
  SESSIONS_LIST_REQUEST,
  SESSIONS_LIST_SUCCESS,
  SESSIONS_LIST_FAILURE,
  SESSIONS_DETAILS_REQUEST,
  SESSIONS_DETAILS_SUCCESS,
  SESSIONS_DETAILS_FAILURE,
  SESSIONS_CREATE_REQUEST,
  SESSIONS_CREATE_SUCCESS,
  SESSIONS_CREATE_FAILURE,
  SESSIONS_CREATE_RESET,
  SESSIONS_UPDATE_REQUEST,
  SESSIONS_UPDATE_SUCCESS,
  SESSIONS_UPDATE_FAILURE,
  SESSIONS_UPDATE_RESET,
} from "../types/sessionTypes";

const initialState = {
  loading: true,
  sessions: [],
  filteredSessions: [],
  allSessions: {},
};

export const allSessionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case ALL_SESSIONS_LIST_REQUEST:
      return { loading: true };
    case ALL_SESSIONS_LIST_SUCCESS:
      return {
        loading: false,
        allSessions: action.payload,
      };
    case ALL_SESSIONS_LIST_FAILURE:
      return {
        loading: false,
        error: action.payload,
        sessions: [],
        filteredSessions: [],
        allSessions: {},
      };
    default:
      return state;
  }
};

export const sessionsListReducer = (state = initialState, action) => {
  switch (action.type) {
    case SESSIONS_LIST_REQUEST:
      return { loading: true };
    case SESSIONS_LIST_SUCCESS:
      return {
        loading: false,
        sessions: action.payload,
        filteredSessions: action.payload,
      };
    case SESSIONS_LIST_FAILURE:
      return {
        loading: false,
        error: action.payload,
        sessions: [],
        filteredSessions: [],
      };
    default:
      return state;
  }
};

export const sessionsDetailReducer = (state = { loading: true }, action) => {
  switch (action.type) {
    case SESSIONS_DETAILS_REQUEST:
      return { loading: true };
    case SESSIONS_DETAILS_SUCCESS:
      return { loading: false, session: action.payload };
    case SESSIONS_DETAILS_FAILURE:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const sessionsCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case SESSIONS_CREATE_REQUEST:
      return { loading: true };
    case SESSIONS_CREATE_SUCCESS:
      return { loading: false, success: true, sessions: action.payload };
    case SESSIONS_CREATE_FAILURE:
      return { loading: false, error: action.payload };
    case SESSIONS_CREATE_RESET:
      return {};
    default:
      return state;
  }
};

export const sessionsUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case SESSIONS_UPDATE_REQUEST:
      return { loading: true };
    case SESSIONS_UPDATE_SUCCESS:
      return { loading: false, success: true };
    case SESSIONS_UPDATE_FAILURE:
      return { loading: false, error: action.payload };
    case SESSIONS_UPDATE_RESET:
      return {};
    default:
      return state;
  }
};
