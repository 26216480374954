import PropType from "prop-types";
import React from "react";

const MessageDisplay = ({ message, description, buttonLabel, action }) => (
  <div className="loader-box">
    <div className="font-regular-23">{message || "Message"}</div>

    {description && <div className="font-regular-18">{description}</div>}
    <br />

    {action && (
      <button className="btn btn-primary" onClick={action} type="button">
        {buttonLabel || "Okay"}
      </button>
    )}
  </div>
);

MessageDisplay.defaultProps = {
  description: undefined,
  buttonLabel: "Okay",
  action: undefined,
};

MessageDisplay.propTypes = {
  message: PropType.string.isRequired,
  description: PropType.string,
  buttonLabel: PropType.string,
  action: PropType.func,
};

export default MessageDisplay;
