import {
  SESSION_ATTACHMENTS_LIST_REQUEST,
  SESSION_ATTACHMENTS_LIST_SUCCESS,
  SESSION_ATTACHMENTS_LIST_FAILURE,
  SESSION_ATTACHMENTS_DETAILS_REQUEST,
  SESSION_ATTACHMENTS_DETAILS_SUCCESS,
  SESSION_ATTACHMENTS_DETAILS_FAILURE,
  SESSION_ATTACHMENTS_CREATE_REQUEST,
  SESSION_ATTACHMENTS_CREATE_SUCCESS,
  SESSION_ATTACHMENTS_CREATE_FAILURE,
  SESSION_ATTACHMENTS_CREATE_RESET,
  SESSION_ATTACHMENTS_UPDATE_REQUEST,
  SESSION_ATTACHMENTS_UPDATE_SUCCESS,
  SESSION_ATTACHMENTS_UPDATE_FAILURE,
  SESSION_ATTACHMENTS_UPDATE_RESET,
} from "../types/sessionAttachmentTypes";

const initialState = {
  loading: true,
  sessionAttachments: [],
  filteredSessionAttachments: [],
};

export const sessionAttachmentsListReducer = (state = initialState, action) => {
  switch (action.type) {
    case SESSION_ATTACHMENTS_LIST_REQUEST:
      return { loading: true };
    case SESSION_ATTACHMENTS_LIST_SUCCESS:
      return {
        loading: false,
        sessionAttachments: action.payload,
        filteredSessionAttachments: action.payload,
      };
    case SESSION_ATTACHMENTS_LIST_FAILURE:
      return {
        loading: false,
        error: action.payload,
        sessionAttachments: [],
        filteredSessionAttachments: [],
      };
    default:
      return state;
  }
};

export const sessionAttachmentsDetailReducer = (
  state = { loading: true },
  action,
) => {
  switch (action.type) {
    case SESSION_ATTACHMENTS_DETAILS_REQUEST:
      return { loading: true };
    case SESSION_ATTACHMENTS_DETAILS_SUCCESS:
      return { loading: false, sessionAttachment: action.payload };
    case SESSION_ATTACHMENTS_DETAILS_FAILURE:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const sessionAttachmentsCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case SESSION_ATTACHMENTS_CREATE_REQUEST:
      return { loading: true };
    case SESSION_ATTACHMENTS_CREATE_SUCCESS:
      return {
        loading: false,
        success: true,
        sessionAttachments: action.payload,
      };
    case SESSION_ATTACHMENTS_CREATE_FAILURE:
      return { loading: false, error: action.payload };
    case SESSION_ATTACHMENTS_CREATE_RESET:
      return {};
    default:
      return state;
  }
};

export const sessionAttachmentsUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case SESSION_ATTACHMENTS_UPDATE_REQUEST:
      return { loading: true };
    case SESSION_ATTACHMENTS_UPDATE_SUCCESS:
      return { loading: false, success: true };
    case SESSION_ATTACHMENTS_UPDATE_FAILURE:
      return { loading: false, error: action.payload };
    case SESSION_ATTACHMENTS_UPDATE_RESET:
      return {};
    default:
      return state;
  }
};
