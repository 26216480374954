export const TESTIMONIALS_LIST_REQUEST = "TESTIMONIALS_LIST_REQUEST";
export const TESTIMONIALS_LIST_SUCCESS = "TESTIMONIALS_LIST_SUCCESS";
export const TESTIMONIALS_LIST_FAILURE = "TESTIMONIALS_LIST_FAILURE";

export const TESTIMONIALS_DETAILS_REQUEST = "TESTIMONIALS_DETAILS_REQUEST";
export const TESTIMONIALS_DETAILS_SUCCESS = "TESTIMONIALS_DETAILS_SUCCESS";
export const TESTIMONIALS_DETAILS_FAILURE = "TESTIMONIALS_DETAILS_FAILURE";

export const TESTIMONIALS_CREATE_REQUEST = "TESTIMONIALS_CREATE_REQUEST";
export const TESTIMONIALS_CREATE_SUCCESS = "TESTIMONIALS_CREATE_SUCCESS";
export const TESTIMONIALS_CREATE_FAILURE = "TESTIMONIALS_CREATE_FAILURE";
export const TESTIMONIALS_CREATE_RESET = "TESTIMONIALS_CREATE_RESET";

export const TESTIMONIALS_UPDATE_REQUEST = "TESTIMONIALS_UPDATE_REQUEST";
export const TESTIMONIALS_UPDATE_SUCCESS = "TESTIMONIALS_UPDATE_SUCCESS";
export const TESTIMONIALS_UPDATE_FAILURE = "TESTIMONIALS_UPDATE_FAILURE";
export const TESTIMONIALS_UPDATE_RESET = "TESTIMONIALS_UPDATE_RESET";
