import { createStore, compose, applyMiddleware } from "redux";
import thunk from "redux-thunk";
// import { composeWithDevTools } from "redux-devtools-extension";
// reducer
import rootReducer from "./reducers/index";

// Create store
// const appStore = createStore(rootReducer);

// export default appStore;

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const appStore = createStore(
  rootReducer,
  composeEnhancer(applyMiddleware(thunk)),
  // composeWithDevTools(applyMiddleware(thunk))
);

export default appStore;
