export const EVENTS_LIST_REQUEST = "EVENTS_LIST_REQUEST";
export const EVENTS_LIST_SUCCESS = "EVENTS_LIST_SUCCESS";
export const EVENTS_LIST_FAIL = "EVENTS_LIST_FAIL";

export const EVENTS_DETAILS_REQUEST = "EVENTS_DETAILS_REQUEST";
export const EVENTS_DETAILS_SUCCESS = "EVENTS_DETAILS_SUCCESS";
export const EVENTS_DETAILS_FAIL = "EVENTS_DETAILS_FAIL";

export const EVENTS_CREATE_REQUEST = "EVENTS_CREATE_REQUEST";
export const EVENTS_CREATE_SUCCESS = "EVENTS_CREATE_SUCCESS";
export const EVENTS_CREATE_FAIL = "EVENTS_CREATE_FAIL";
export const EVENTS_CREATE_RESET = "EVENTS_CREATE_RESET";

export const EVENTS_UPDATE_REQUEST = "EVENTS_UPDATE_REQUEST";
export const EVENTS_UPDATE_SUCCESS = "EVENTS_UPDATE_SUCCESS";
export const EVENTS_UPDATE_FAIL = "EVENTS_UPDATE_FAIL";
export const EVENTS_UPDATE_RESET = "EVENTS_UPDATE_RESET";

export const EVENTS_STATS_REQUEST = "EVENTS_STATS_REQUEST";
export const EVENTS_STATS_SUCCESS = "EVENTS_STATS_SUCCESS";
export const EVENTS_STATS_FAIL = "EVENTS_STATS_FAIL";
export const EVENTS_STATS_RESET = "EVENTS_STATS_RESET";

export const TOGGLE_CREATE_EVENT_PAGE_SHOWN = "TOGGLE_CREATE_EVENT_PAGE_SHOWN";
