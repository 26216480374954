import {
  SECTORS_LIST_REQUEST,
  SECTORS_LIST_SUCCESS,
  SECTORS_LIST_FAILURE,
  SECTORS_CREATE_REQUEST,
  SECTORS_CREATE_SUCCESS,
  SECTORS_CREATE_FAILURE,
} from "../types/sectorTypes";

const initialState = {
  loading: true,
  sectors: [],
  filteredSectors: [],
};

// reducer
export const sectorsListReducer = (state = initialState, action) => {
  switch (action.type) {
    case SECTORS_LIST_REQUEST:
      return { loading: true };
    case SECTORS_LIST_SUCCESS:
      return {
        loading: false,
        sectors: action.payload,
        filteredSectors: action.payload,
      };
    case SECTORS_LIST_FAILURE:
      return {
        loading: false,
        error: action.payload,
        sectors: [],
        filteredSectors: [],
      };
    default:
      return state;
  }
};

export const sectorsCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case SECTORS_CREATE_REQUEST:
      return { loading: true };
    case SECTORS_CREATE_SUCCESS:
      return { loading: false, success: true, sectors: action.payload };
    case SECTORS_CREATE_FAILURE:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
