export const MAX_FILE_SIZE = 10000000;
export const MAX_NUMBER_OF_FILES = 50;
export const ACCEPTED_IMG_FILES = ["image/png", "image/jpeg", "image/jpg"];
export const ACCEPTED_TESTIMONIAL_FILES = [
  "application/pdf",
  "image/png",
  "image/jpeg",
  "image/jpg",
  ".doc",
  ".docx",
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
];
export const ACCEPTED_PARTICIPANT_FILES = [
  "csv",
  "text/csv",
  "xls",
  "xlsx",
  "application/vnd.ms-excel",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
];

export const IMAGE_EXTENSIONS = ["png", "jpg", "jpeg"];
export const DOC_PDF_EXTENSIONS = ["docx", "doc", "pdf"];

export const API_KEY_INVALID = "API key is invalid";
export const NOT_FOUND = "Details not found";
export const AUTH_FAIL = "status code 401";

export const AUTOSUGGEST_TYPE_CITY = "city";
export const AUTOSUGGEST_TYPE_ORGANIZATION = "organization";
export const AUTOSUGGEST_TYPE_CATEGORY = "category";
export const ALL_ORGANIZATIONS = "all";

export const LOCATION_SCOPE_OPTIONS = [
  { name: "One-City", label: "One-City", value: "one_city" },
  {
    name: "Multi-City",
    label: "Multi-City",
    value: "multi_city",
  },
  // The below comments should not be removed as backend have the same value
  // {
  //   name: "Country-wide",
  //   label: "Country-wide",
  //   value: "country_wide",
  // },
  // { name: "Global", label: "Global", value: "global" },
];

export const ONE_CITY_FRONT_END = "One-City";
export const ONE_CITY_BACK_END = "one_city";
export const MULTI_CITY_FRONT_END = "Multi-City";
export const MULTI_CITY_BACK_END = "multi_city";

export const EVENT_TYPE_OPTIONS = [
  { name: "1-session", label: "1-session" },
  { name: "3-day", label: "3-day" },
  { name: "'n' week program", label: "'n' week program" },
  { name: "Retreat", label: "Retreat" },
  { name: "Seminar", label: "Seminar" },
  { name: "Webinars", label: "Webinars" },
  { name: "Follow-up", label: "Follow-up" },
  { name: "Open-ended", label: "Open-ended" },
  { name: "Others", label: "Others" },
];

export const GOVT_FLAG_ENABLED_SECTOR = ["Defence / Police", "Government Dept"];
export const HFN_COORDINATOR = "hfn_coordinator";
export const POINT_OF_CONTACT = "point_of_contact";
export const REGULAR_PARTICIPANT = "regular_participant";

export const PAGE_SIZE = 10;
