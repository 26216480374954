import {
  TESTIMONIAL_ATTACHMENTS_LIST_REQUEST,
  TESTIMONIAL_ATTACHMENTS_LIST_SUCCESS,
  TESTIMONIAL_ATTACHMENTS_LIST_FAILURE,
  TESTIMONIAL_ATTACHMENTS_DETAILS_REQUEST,
  TESTIMONIAL_ATTACHMENTS_DETAILS_SUCCESS,
  TESTIMONIAL_ATTACHMENTS_DETAILS_FAILURE,
  TESTIMONIAL_ATTACHMENTS_CREATE_REQUEST,
  TESTIMONIAL_ATTACHMENTS_CREATE_SUCCESS,
  TESTIMONIAL_ATTACHMENTS_CREATE_FAILURE,
  TESTIMONIAL_ATTACHMENTS_CREATE_RESET,
  TESTIMONIAL_ATTACHMENTS_UPDATE_REQUEST,
  TESTIMONIAL_ATTACHMENTS_UPDATE_SUCCESS,
  TESTIMONIAL_ATTACHMENTS_UPDATE_FAILURE,
  TESTIMONIAL_ATTACHMENTS_UPDATE_RESET,
} from "../types/testimonialAttachmentTypes";

const initialState = {
  loading: true,
  testimonialAttachments: [],
  filteredTestimonialAttachments: [],
};

export const testimonialAttachmentsListReducer = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case TESTIMONIAL_ATTACHMENTS_LIST_REQUEST:
      return { loading: true };
    case TESTIMONIAL_ATTACHMENTS_LIST_SUCCESS:
      return {
        loading: false,
        testimonialAttachments: action.payload,
        filteredTestimonialAttachments: action.payload,
      };
    case TESTIMONIAL_ATTACHMENTS_LIST_FAILURE:
      return {
        loading: false,
        error: action.payload,
        testimonialAttachments: [],
        filteredTestimonialAttachments: [],
      };
    default:
      return state;
  }
};

export const testimonialAttachmentsDetailReducer = (
  state = { loading: true },
  action,
) => {
  switch (action.type) {
    case TESTIMONIAL_ATTACHMENTS_DETAILS_REQUEST:
      return { loading: true };
    case TESTIMONIAL_ATTACHMENTS_DETAILS_SUCCESS:
      return { loading: false, testimonialAttachment: action.payload };
    case TESTIMONIAL_ATTACHMENTS_DETAILS_FAILURE:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const testimonialAttachmentsCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case TESTIMONIAL_ATTACHMENTS_CREATE_REQUEST:
      return { loading: true };
    case TESTIMONIAL_ATTACHMENTS_CREATE_SUCCESS:
      return {
        loading: false,
        success: true,
        testimonialAttachments: action.payload,
      };
    case TESTIMONIAL_ATTACHMENTS_CREATE_FAILURE:
      return { loading: false, error: action.payload };
    case TESTIMONIAL_ATTACHMENTS_CREATE_RESET:
      return {};
    default:
      return state;
  }
};

export const testimonialAttachmentsUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case TESTIMONIAL_ATTACHMENTS_UPDATE_REQUEST:
      return { loading: true };
    case TESTIMONIAL_ATTACHMENTS_UPDATE_SUCCESS:
      return { loading: false, success: true };
    case TESTIMONIAL_ATTACHMENTS_UPDATE_FAILURE:
      return { loading: false, error: action.payload };
    case TESTIMONIAL_ATTACHMENTS_UPDATE_RESET:
      return {};
    default:
      return state;
  }
};
