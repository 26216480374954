import {
  APPLY_FILTER,
  MULTIPLE_FILTER,
  RESET_FILTER,
} from "../types/filterTypes";
import { ALL_ORGANIZATIONS } from "../../assets/constants";

const initialState = {
  search: "",
  category: ALL_ORGANIZATIONS,
  multiFilter: [],
  eventsCount: 0,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case RESET_FILTER:
      return initialState;
    case APPLY_FILTER:
      return {
        ...state,
        ...action.payload,
      };

    case MULTIPLE_FILTER:
      return {
        ...state,
        search: action.payload.searchedItem,
        multiFilter: action.payload.data.results,
        eventsCount: action.payload.data.count,
      };
    default:
      return state;
  }
};
