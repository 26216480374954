export const TESTIMONIAL_ATTACHMENTS_LIST_REQUEST =
  "TESTIMONIAL_ATTACHMENTS_LIST_REQUEST";
export const TESTIMONIAL_ATTACHMENTS_LIST_SUCCESS =
  "TESTIMONIAL_ATTACHMENTS_LIST_SUCCESS";
export const TESTIMONIAL_ATTACHMENTS_LIST_FAILURE =
  "TESTIMONIAL_ATTACHMENTS_LIST_FAILURE";

export const TESTIMONIAL_ATTACHMENTS_DETAILS_REQUEST =
  "TESTIMONIAL_ATTACHMENTS_DETAILS_REQUEST";
export const TESTIMONIAL_ATTACHMENTS_DETAILS_SUCCESS =
  "TESTIMONIAL_ATTACHMENTS_DETAILS_SUCCESS";
export const TESTIMONIAL_ATTACHMENTS_DETAILS_FAILURE =
  "TESTIMONIAL_ATTACHMENTS_DETAILS_FAILURE";

export const TESTIMONIAL_ATTACHMENTS_CREATE_REQUEST =
  "TESTIMONIAL_ATTACHMENTS_CREATE_REQUEST";
export const TESTIMONIAL_ATTACHMENTS_CREATE_SUCCESS =
  "TESTIMONIAL_ATTACHMENTS_CREATE_SUCCESS";
export const TESTIMONIAL_ATTACHMENTS_CREATE_FAILURE =
  "TESTIMONIAL_ATTACHMENTS_CREATE_FAILURE";
export const TESTIMONIAL_ATTACHMENTS_CREATE_RESET =
  "TESTIMONIAL_ATTACHMENTS_CREATE_RESET";

export const TESTIMONIAL_ATTACHMENTS_UPDATE_REQUEST =
  "TESTIMONIAL_ATTACHMENTS_UPDATE_REQUEST";
export const TESTIMONIAL_ATTACHMENTS_UPDATE_SUCCESS =
  "TESTIMONIAL_ATTACHMENTS_UPDATE_SUCCESS";
export const TESTIMONIAL_ATTACHMENTS_UPDATE_FAILURE =
  "TESTIMONIAL_ATTACHMENTS_UPDATE_FAILURE";
export const TESTIMONIAL_ATTACHMENTS_UPDATE_RESET =
  "TESTIMONIAL_ATTACHMENTS_UPDATE_RESET";
