export const LOCATIONS_LIST_REQUEST = "LOCATIONS_LIST_REQUEST";
export const LOCATIONS_LIST_SUCCESS = "LOCATIONS_LIST_SUCCESS";
export const LOCATIONS_LIST_FAILURE = "LOCATIONS_LIST_FAILURE";

export const LOCATIONS_CREATE_REQUEST = "LOCATIONS_CREATE_REQUEST";
export const LOCATIONS_CREATE_SUCCESS = "LOCATIONS_CREATE_SUCCESS";
export const LOCATIONS_CREATE_FAILURE = "LOCATIONS_CREATE_FAILURE";

export const LOCATIONS_UPDATE_REQUEST = "LOCATIONS_UPDATE_REQUEST";
export const LOCATIONS_UPDATE_SUCCESS = "LOCATIONS_UPDATE_SUCCESS";
export const LOCATIONS_UPDATE_FAILURE = "LOCATIONS_UPDATE_FAILURE";
export const LOCATIONS_UPDATE_RESET = "LOCATIONS_UPDATE_RESET";
