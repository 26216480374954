import React, { StrictMode } from "react";
// import { ToastContainer } from "react-toastify";
import { Provider } from "react-redux";
import PropType from "prop-types";
// import cx from "classnames";
import { BrowserRouter as Router } from "react-router-dom";
// import AppRouter from "./routes";
import "react-toastify/dist/ReactToastify.css";
import "./index.scss";
import ErrorBoundary from "./shared/core/error-boundary";

const App = ({ store }) => {
  return (
    <StrictMode>
      <Provider store={store}>
        <Router>
          <ErrorBoundary>
            {/* <div className={cx("app-container", "hide-header")}>
              <AppRouter />
              <ToastContainer />
            </div> */}
            <div className="maintenance-mode">
              <h2>Under Maintenance</h2>
              <p>
                Sorry for the inconvenience caused. We are currently under
                maintenance. Please check after 07:00 PM (IST).
              </p>
            </div>
          </ErrorBoundary>
        </Router>
      </Provider>
    </StrictMode>
  );
};

App.propTypes = {
  store: PropType.shape({}).isRequired,
};

export default App;
