export const PARTNERS_LIST_REQUEST = "PARTNERS_LIST_REQUEST";
export const PARTNERS_LIST_SUCCESS = "PARTNERS_LIST_SUCCESS";
export const PARTNERS_LIST_FAILURE = "PARTNERS_LIST_FAILURE";

export const PARTNERS_DETAILS_REQUEST = "PARTNERS_DETAILS_REQUEST";
export const PARTNERS_DETAILS_SUCCESS = "PARTNERS_DETAILS_SUCCESS";
export const PARTNERS_DETAILS_FAILURE = "PARTNERS_DETAILS_FAILURE";

export const PARTNERS_CREATE_REQUEST = "PARTNERS_CREATE_REQUEST";
export const PARTNERS_CREATE_SUCCESS = "PARTNERS_CREATE_SUCCESS";
export const PARTNERS_CREATE_FAILURE = "PARTNERS_CREATE_FAILURE";
export const PARTNERS_CREATE_RESET = "PARTNERS_CREATE_RESET";

export const PARTNERS_UPDATE_REQUEST = "PARTNERS_UPDATE_REQUEST";
export const PARTNERS_UPDATE_SUCCESS = "PARTNERS_UPDATE_SUCCESS";
export const PARTNERS_UPDATE_FAILURE = "PARTNERS_UPDATE_FAILURE";
export const PARTNERS_UPDATE_RESET = "PARTNERS_UPDATE_RESET";
